/*
* IMPORTANT: Training are considered Usecases on the frontend. On the past we changed the labels, but due to wordpress limitations, 
* we can't change the handle to `usecase` and have to maintain `trainings`
* Because gastby requires the name of the template to have the same name of the graphql item, we will live with training forever, to avoid migrations.
*
*/
import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import TrainingLayout from "../../components/trainingLayout"
import { useLocation } from "@reach/router"


const Training = ({ data }) => {
  const location = useLocation()

  return (
    <TrainingLayout postData={data.wpTraining} locationHash={location.hash} />
  )
}

Training.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Training

export const pageQuery = graphql`
  query($id: String!) {
    wpTraining(id: { eq: $id }) {
      databaseId
      title
      content
      author {
        node {
          acf {
            tenjinWorkTitle
            tenjinWebsite
          }
          avatar {
            url
          }
          email
          name
          url
        }
      }
      acfAuthor {
        tenjinAuthor {
          ... on WpCustom_author {
            featuredImage {
              node {
                sourceUrl
              }
            }
            title
            acf {
              tenjinAuthorWebsite
              tenjinAuthorJob
            }
          }
        }
      }
      acf {
        tenjinArticleNumber
        tenjinHeadline
        tenjinTrainingStepsHide
        tenjinTrainingSteps {
          tenjinStepTitle
          tenjinTrainingStepContent
        }
        tenjinRelatedContent {
          ... on WpTraining {
            databaseId
            nodeType
            title
            slug
            uri
            acf {
              tenjinCardExcerpt
              tenjinArticleNumber
              tenjinUpcomingContent
              tenjinNewContent
              tenjinPaidContent
              tenjinRelatedContent {
                ... on WpAnalyse {
                  databaseId
                  id
                  slug
                  title
                  nodeType
                  uri
                  acf {
                    iconColor {
                      mediaDetails {
                        height
                        width
                      }
                      sourceUrl
                    }
                    tenjinUpcomingContent
                    tenjinNoContent
                    tenjinNewContent
                  }
                }
                ... on WpDestination {
                  databaseId
                  id
                  slug
                  title
                  nodeType
                  uri
                  acf {
                    iconColor {
                      mediaDetails {
                        height
                        width
                      }
                      sourceUrl
                    }
                    tenjinUpcomingContent
                    tenjinNoContent
                    tenjinNewContent
                  }
                }
                ... on WpSource {
                  databaseId
                  id
                  slug
                  title
                  nodeType
                  uri
                  acf {
                    iconColor {
                      mediaDetails {
                        height
                        width
                      }
                      sourceUrl
                    }
                    tenjinUpcomingContent
                    tenjinNoContent
                    tenjinNewContent
                  }
                }
              }
            }
          }
          ... on WpTutorial {
            databaseId
            nodeType
            title
            slug
            uri
            acf {
              tenjinCardExcerpt
              tenjinArticleNumber
              tenjinUpcomingContent
              tenjinNewContent
              tenjinPaidContent
              tenjinRelatedContent {
                ... on WpAnalyse {
                  databaseId
                  id
                  slug
                  title
                  nodeType
                  uri
                  acf {
                    iconColor {
                      mediaDetails {
                        height
                        width
                      }
                      sourceUrl
                    }
                    tenjinUpcomingContent
                    tenjinNoContent
                    tenjinNewContent
                  }
                }
                ... on WpDestination {
                  databaseId
                  id
                  slug
                  title
                  nodeType
                  uri
                  acf {
                    iconColor {
                      mediaDetails {
                        height
                        width
                      }
                      sourceUrl
                    }
                    tenjinUpcomingContent
                    tenjinNoContent
                    tenjinNewContent
                  }
                }
                ... on WpSource {
                  databaseId
                  id
                  slug
                  title
                  nodeType
                  uri
                  acf {
                    iconColor {
                      mediaDetails {
                        height
                        width
                      }
                      sourceUrl
                    }
                    tenjinUpcomingContent
                    tenjinNoContent
                    tenjinNewContent
                  }
                }
              }
            }
          }
          ... on WpAnalyse {
            id
            acf {
              iconColor {
                sourceUrl
                mediaDetails {
                  height
                  width
                }
              }
              tenjinUpcomingContent
              tenjinNoContent
              tenjinNewContent
              tenjinPopularity
            }
            title
            nodeType
            uri
            slug
            date
            categories: analyseCategories {
              nodes {
                slug
                name
              }
            }
          }
          ... on WpDestination {
            id
            acf {
              iconColor {
                sourceUrl
                mediaDetails {
                  height
                  width
                }
              }
              tenjinUpcomingContent
              tenjinNoContent
              tenjinNewContent
              tenjinPopularity
            }
            title
            nodeType
            uri
            slug
            date
            categories: destinationCategories {
              nodes {
                slug
                name
              }
            }
          }
          ... on WpSource {
            id
            acf {
              iconColor {
                sourceUrl
                mediaDetails {
                  height
                  width
                }
              }
              tenjinUpcomingContent
              tenjinNoContent
              tenjinNewContent
              tenjinPopularity
            }
            title
            nodeType
            uri
            slug
            date
            categories: sourceCategories {
              nodes {
                slug
                name
              }
            }
          }
        }
        tenjinUpcomingContent
        tenjinNewContent
        tenjinPaidContent
      },
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
        canonical
        cornerstone
        schema {
            articleType
            pageType
            raw
        }
      }
    }
  }
`
